import React, { Fragment, useState } from "react";
import withRotator from "./rotator";
import Testimonial from "./testimonial";
import ModalVideo from "react-modal-video";

const TestimonialRow = ({ items, clickLeft, clickRight }) => {
  const [isOpen, setOpen] = useState(false);
  if (items.length === 0) {
    return false;
  }
  let [head, ...tail] = items;
  return (
    <Fragment>
      <div className="videoTestimonials">
        <div className="mainVideo">
          <div className="mainVideoText">
            <h3>{head.testimonial}</h3>
            <p className="testName">{head.name}</p>
            <p className="testJob">{head.job}</p>
          </div>
          <div
            className="mainVideoImage"
            style={{ backgroundImage: `url("${head.image}")` }}
            onClick={() => setOpen(true)}
          >
            <div className="mainVideoPlay">
              <img src="icon-play.svg" />
              Δες το βίντεο
            </div>
          </div>
          <ModalVideo
            channel="youtube"
            youtube={{
              autoplay: 1,
              modestbranding: true,
              rel: 0,
            }}
            autoplay
            isOpen={isOpen}
            videoId={head.video}
            onClose={() => setOpen(false)}
          />
        </div>
        <div className="videoRow">
          <div className="videoOthers">
            {tail.map((item, index) => (
              <Testimonial item={item} key={index} index={index} />
            ))}
          </div>
          <div className="rotateIcons">
            <a onClick={clickLeft} className="rotateLeft">
              <img src="/back.svg" />
            </a>
            <a onClick={clickRight} className="rotateRight">
              <img src="/forward.svg" />
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const TestimonialsWithRotator = withRotator(TestimonialRow, false, false);



const TestimonialsHolder = ({ data, lang }) => {
  const title = data && data.find((el) => el.node.label === "testimonial-title-1")
  const title1 = data && data.find((el) => el.node.label === "testimonial-title-2")
  const testimonialText1 = data && data.find((el) => el.node.label === "testimonial-1-text")
  const testimonialText2 = data && data.find((el) => el.node.label === "testimonial-2-text")
  const testimonialText3 = data && data.find((el) => el.node.label === "testimonial-3-text")
  const testimonialText4 = data && data.find((el) => el.node.label === "testimonial-4-text")
  const testimonialText6 = data && data.find((el) => el.node.label === "testimonial-6-text")
  const testimonialText7 = data && data.find((el) => el.node.label === "testimonial-7-text")
  const testimonialText8 = data && data.find((el) => el.node.label === "testimonial-8-text")
  const testimonialName1 = data && data.find((el) => el.node.label === "testimonial-1-name")
  const testimonialName2 = data && data.find((el) => el.node.label === "testimonial-2-name")
  const testimonialName3 = data && data.find((el) => el.node.label === "testimonial-3-name")
  const testimonialName4 = data && data.find((el) => el.node.label === "testimonial-4-name")
  const testimonialName5 = data && data.find((el) => el.node.label === "testimonial-5-name")
  const testimonialName6 = data && data.find((el) => el.node.label === "testimonial-6-name")
  const testimonialName7 = data && data.find((el) => el.node.label === "testimonial-7-name")
  const testimonialName8 = data && data.find((el) => el.node.label === "testimonial-8-name")
  const testimonialJob1 = data && data.find((el) => el.node.label === "testimonial-1-job")
  const testimonialJob2 = data && data.find((el) => el.node.label === "testimonial-2-title")
  const testimonialJob3 = data && data.find((el) => el.node.label === "testimonial-3-title")
  const testimonialJob4 = data && data.find((el) => el.node.label === "testimonial-4-title")
  const testimonialJob5 = data && data.find((el) => el.node.label === "testimonial-5-title")
  const testimonialJob6 = data && data.find((el) => el.node.label === "testimonial-6-title")
  const testimonialJob7 = data && data.find((el) => el.node.label === "testimonial-7-title")
  const testimonialJob8 = data && data.find((el) => el.node.label === "testimonial-8-title")

  const testimonials = [
    {
      name: testimonialName1 && testimonialName1.node[lang],
      job: testimonialJob1 && testimonialJob1.node[lang],
      testimonial: `“${testimonialText1 && testimonialText1.node[lang]}”`,
      image: "/alexia.png",
      video: "63pTNq6C128",
    },
    {
      name: testimonialName2 && testimonialName2.node[lang],
      job: testimonialJob2 && testimonialJob2.node[lang],
      testimonial:
        `“${testimonialText2 && testimonialText2.node[lang]}”`,
      image: "/melina.png",
      video: "qFtOoVKQFBE",
    },
    {
      name: testimonialName3 && testimonialName3.node[lang],
      job: testimonialJob3 && testimonialJob3.node[lang],
      testimonial: `“${testimonialText3 && testimonialText3.node[lang]}”`,
      image: "/niki.png",
      video: "OnG1Snf_ve4",
    },
    {
      name: testimonialName4 && testimonialName4.node[lang],
      job: testimonialJob4 && testimonialJob4.node[lang],
      testimonial: `“${testimonialText4 && testimonialText4.node[lang]}”`,
      image: "/mairi.png",
      video: "Z587OlJS4W0",
    },
    {
      name: testimonialName5 && testimonialName5.node[lang],
      job: testimonialJob5 && testimonialJob5.node[lang],
      testimonial: `“${testimonialText3 && testimonialText3.node[lang]}”`,
      image: "/anastasia.png",
      video: "Mx9mLXVucH0",
    },
    {
      name: testimonialName6 && testimonialName6.node[lang],
      job: testimonialJob6 && testimonialJob6.node[lang],
      testimonial: `“${testimonialText6 && testimonialText6.node[lang]}”`,
      image: "/natalia.png",
      video: "BdbyeIsgYBU",
    },
    {
      name: testimonialName7 && testimonialName7.node[lang],
      job: testimonialJob7 && testimonialJob7.node[lang],
      testimonial: `“${testimonialText7 && testimonialText7.node[lang]}”`,
      image: "/maria.png",
      video: "9lXj87BmWVE",
    },
    {
      name: testimonialName8 && testimonialName8.node[lang],
      job: testimonialJob8 && testimonialJob8.node[lang],
      testimonial: `“${testimonialText8 && testimonialText8.node[lang]}”`,
      image: "/kate.png",
      video: "JpWE0W3USGU",
    },
  ];


  return (
    <div className="container videos">
      <h3>{title && title.node[lang]}</h3>
      <p>
        {title1 && title1.node[lang].split("Great")[0]}<strong>Great</strong> {title1 && title1.node[lang].split("Great")[1]}
      </p>
      <TestimonialsWithRotator items={testimonials} centered={true} />
    </div>
  );
};

export default TestimonialsHolder;

