import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Hero from "../components/hero";
import VitaminIntro from "../components/vitaminIntro";
import Benefits from "../components/benefits";
import Features from "../components/features";
import TestimonialsHolder from "../components/testimonialsHolder";
import Follow from "../components/follow";
import { HalfButton2, Guarantees } from "../components/button";
import Experts from "../components/experts";
import Community from "../components/community";
import { getLabels } from "../utils/actions";

const IndexPage = ({ data }) => {
  const [lang, setLang] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );

  const featuresTitle1 = data.allLabels.edges.find(
    el => el.node.label === "home-section-2-title"
  );
  const featuresTitle2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-3-title"
  );
  const featuresTitle3 = data.allLabels.edges.find(
    el => el.node.label === "home-section-4-title"
  );
  const featuresSubText1 = data.allLabels.edges.find(
    el => el.node.label === "home-section-2"
  );
  const featuresSubText2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-3-description"
  );
  const featuresSubText3 = data.allLabels.edges.find(
    el => el.node.label === "home-section-4-description"
  );
  const featuresButton1 = data.allLabels.edges.find(
    el => el.node.label === "home-section-2-button"
  );
  const featuresButton2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-7-button"
  );

  const benefitTitle1 = data.allLabels.edges.find(
    el => el.node.label === "home-section-5-title"
  );
  const benefitTitle2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-6-title"
  );
  const benefitTitle3 = data.allLabels.edges.find(
    el => el.node.label === "home-section-7-title"
  );
  const benefitTitle4 = data.allLabels.edges.find(
    el => el.node.label === "home-section-8-title"
  );
  const benefitSubText1 = data.allLabels.edges.find(
    el => el.node.label === "home-section-5-description"
  );
  const benefitSubText2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-6-description"
  );
  const benefitSubText3 = data.allLabels.edges.find(
    el => el.node.label === "home-section-7-description"
  );
  const benefitSubText4 = data.allLabels.edges.find(
    el => el.node.label === "home-section-8-description"
  );
  const benefitButton1 = data.allLabels.edges.find(
    el => el.node.label === "home-page-button-1"
  );
  const benefitButton2 = data.allLabels.edges.find(
    el => el.node.label === "home-section-6-button"
  );
  const benefitButton3 = data.allLabels.edges.find(
    el => el.node.label === "home-section-8-button"
  );


  const features = [
    {
      title: `${featuresTitle1 && featuresTitle1.node[lang]} 🤍`,
      description: () => (
        <p>
          {featuresSubText1 && featuresSubText1.node[lang]}
        </p>
      ),
      ctaText: featuresButton1 && featuresButton1.node[lang],
      ctaLink: "/vitamin",
      video: "n36X-YnWEUU",
    },
    {
      title: featuresTitle2 && featuresTitle2.node[lang],
      description: () => (
        <p>
          {featuresSubText2 && featuresSubText2.node[lang]}
          <span role="img">💁‍♀️</span>
        </p>
      ),
      ctaText: featuresButton2 && featuresButton2.node[lang],
      ctaLink: "/vitamin",
      image: "/hair2.jpg",
    },
    {
      title: featuresTitle3 && featuresTitle3.node[lang],
      description: () => (
        <p>
          {featuresSubText3 && featuresSubText3.node[lang]} <span role="img">🙆‍♀️</span>{" "}
          #thetikosnous
        </p>
      ),
      ctaText: featuresButton1 && featuresButton1.node[lang],
      ctaLink: "/vitamin",
      image: "/energy2.jpg",
    },
  ];

  const benefits = [
    {
      title: benefitTitle1 && benefitTitle1.node[lang],
      description: () => (
        <p>
          {benefitSubText1 && benefitSubText1.node[lang].split("Great")[0]}
          <strong>Great</strong> {benefitSubText1 && benefitSubText1.node[lang].split("Great")[1]}

        </p>
      ),
      ctaText: benefitButton1 && benefitButton1.node[lang],
      ctaLink: "/vitamin",
      image: "/power2.jpg",
    },
    {
      title: benefitTitle2 && benefitTitle2.node[lang],
      description: () => (
        <p>
          {benefitSubText2 && benefitSubText2.node[lang].split("Great")[0]} <strong>Great</strong> {benefitSubText2 && benefitSubText2.node[lang].split("Great")[1]}
        </p>
      ),
      ctaText: benefitButton2 && benefitButton2.node[lang],
      ctaLink: "/vitamin/#allIngs",
      ctaBG: "black",
      ctaArrow: true,
      image: "/petri.jpg",
    },
    {
      title: benefitTitle3 && benefitTitle3.node[lang],
      description: () => (
        <p>
          {benefitSubText3 && benefitSubText3.node[lang].split("Great")[0]} <strong>Great</strong>  {benefitSubText3 && benefitSubText3.node[lang].split("Great")[1]}
        </p>
      ),
      ctaText: featuresButton2 && featuresButton2.node[lang],
      ctaBG: "black",
      ctaArrow: true,
      ctaLink: "/vitamin",
      image: "/immune3.jpg",
    },
    {
      title: benefitTitle4 && benefitTitle4.node[lang],
      description: () => (
        <p>
          {benefitSubText4 && benefitSubText4.node[lang]}
        </p>
      ),
      ctaText: benefitButton3 && benefitButton3.node[lang],
      ctaLink: "/vitamin",
      image: "/package.jpg",
    },
  ];

  return (<Layout>
    <SEO title="" />
    <Hero data={data.allLabels.edges} />
    <VitaminIntro data={data.allLabels.edges} />
    <Features features={features} />
    <HalfButton2 price={lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month"}
      lang={lang}
      data={data.allLabels.edges} />
    <Guarantees data={data && data.allLabels.edges} lang={lang} />
    <Benefits benefits={benefits} />
    <Experts lang={lang} data={data} />
    <div className="hr mt160">&nbsp;</div>
    <TestimonialsHolder data={data.allLabels.edges} lang={lang} />
    <Community images={data.allInstaNode} data={data.allLabels.edges} lang={lang} />
    <Follow lang={lang} data={data.allLabels.edges} />
  </Layout>)
};

export default IndexPage;




export const query = graphql`
  query MyQuery {
    allLabels {
      edges {
        node {
          label
          gr
          en
        }
      }
    }
    allInstaNode(limit: 7, sort: { fields: timestamp, order: DESC }) {
      edges {
        node {
          caption
          mediaType
          original
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 400, maxHeight: 400) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
