import React from "react";

const Testimonial = ({ item, index }) => {
  const { name, job, testimonial } = item;
  return (
    <div
      className="video"
      style={{
        display: index > 1 ? "none" : "block",
        backgroundImage: `url("${item.image}")`,
        backgroundSize: "cover",
        opacity: 0.5,
      }}
    ></div>
  );
};

export default Testimonial;
