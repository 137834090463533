import React from "react";
import FeatureSingle from "./featureSingle";

const Features = ({ features }) => {
  return (
    <div className="container">
      <div className="features">
        {features && features.length
          ? features.map((f, i) => (
            <FeatureSingle
              key={i}
              feature={f}
              isTextSideRight={i % 2 === 0 ? true : false}
              pinkbg={true}
            >
              {f.description()}
            </FeatureSingle>
          ))
          : null}
      </div>
    </div>
  );
};

export default Features;
