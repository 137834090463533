import React, { useState } from "react";
import Follow from "./follow";

const VitaminIntro = props => {
  const [lang, setLang] = useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );


  const entro = props.data && props.data.find((el) => el.node.label === "home-section-1")
  const vegan = props.data && props.data.find((el) => el.node.label === "vegan")
  const natural = props.data && props.data.find((el) => el.node.label === "natural")
  const nonGmo = props.data && props.data.find((el) => el.node.label === "non-gmo-top-bar-1")
  const noSugar = props.data && props.data.find((el) => el.node.label === "no-sugar")
  const glutenFree = props.data && props.data.find((el) => el.node.label === "gluten-free")

  return (
    <div className={`container ${props.className}`}>
      <div className="iconRow">
        <div className="leftSide">
          <div className="imgCapsule">
            <img
              src="/capsule.png"
              width="200px"
              height="200px"
              alt="capsule"
            />
          </div>
          <div className="logoText">
            <img alt="Great for Women" src="/gfw.svg" />
            <p>
              {entro &&
                entro.node[lang]
                  .split(" ")
                  .slice(0, 5)
                  .map(el => el)
                  .join(" ")}
              <br className="desktopOnly" />
              <span className="mobileOnly"> </span>
              {entro &&
                entro.node[lang]
                  .split(" ")
                  .slice(5)
                  .map(el => el)
                  .join(" ")}
            </p>
          </div>
        </div>
        <div className="infoFollow">
          <div className="infoIcons">
            <div className="infoIcon">
              <img src="/leaf.svg" alt="vegan" />
              <p>{vegan && vegan.node[lang]}</p>
            </div>
            <div className="infoIcon">
              <img src="/natural.svg" alt="natural" />
              <p>{natural && natural.node[lang]}</p>
            </div>
            <div className="infoIcon">
              <img src="/dna.svg" alt="nongmo" />
              <p>{nonGmo && nonGmo.node[lang]}</p>
            </div>
            <div className="infoIcon">
              <img src="/nosugar.svg" alt="nosugar" />
              <p>{noSugar && noSugar.node[lang]}</p>
            </div>
            <div className="infoIcon">
              <img src="/glutenfree.svg" alt="glutenfree" />
              <p>{glutenFree && glutenFree.node[lang]}</p>
            </div>
          </div>
          <Follow hideSocial={true} lang={lang} data={props.data} />
        </div>
      </div>
    </div>
  );
};

export default VitaminIntro;
