import React, { Fragment } from "react";
// import { array } from "prop-types";
// import { ArrowLeft } from "./icons/arrows";

export default function withRotator(
  WrappedComponent,
  centered = false,
  showIcons = true
) {
  return class extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        items: [],
      };
    }

    componentDidMount() {
      if (centered === true) {
        this.setupCenteredRotator(this.props.items);
      } else {
        this.setState({ items: this.props.items });
      }
    }

    setupCenteredRotator = (items = []) => {
      if (items.length > 0) {
        let centeredArray = [];
        items.map((item, index) => {
          if (index % 2 === 1) {
            centeredArray.push(item);
          } else {
            centeredArray.unshift(item);
          }
          return item;
        });

        this.setState({ items: centeredArray });
      }
      return null;
    };

    rotateRight = () => {
      let currentOrder = this.state.items;
      currentOrder.push(currentOrder.shift());
      this.setState({ items: currentOrder });
    };

    rotateLeft = () => {
      let currentOrder = this.state.items;
      currentOrder.unshift(currentOrder.pop());
      this.setState({ items: currentOrder });
    };

    render() {
      return (
        <Fragment>
          <WrappedComponent
            {...this.props}
            items={this.state.items}
            clickLeft={this.rotateLeft}
            clickRight={this.rotateRight}
          />
          {showIcons && (
            <div className="rotateIcons">
              <div onClick={this.rotateLeft} className="rotateLeft">
                <img alt="rotate left" src="/left-arrow.svg" />
              </div>
              <div onClick={this.rotateRight} className="rotateRight">
                <img alt="rotate right" src="/right-arrow.svg" />
              </div>
            </div>
          )}
        </Fragment>
      );
    }
  };
}
