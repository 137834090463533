import React from "react";
import { HalfButton2, Guarantees } from "./button";
import moment from "moment-timezone";
moment.tz.setDefault("Europe/Athens");

const Hero = ({ data }) => {

  const [lang, setLang] = React.useState(
    typeof window !== "undefined"
      ? localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "gr"
      : "gr"
  );

  const title = data.find(
    ed => ed.node.label === "home-banner-title"
  );
  const subText = data.find(
    ed => ed.node.label === "home-banner-description"
  );


  return (
    < div
      className={`hero ${moment().isBetween(
        moment("2021-11-25 00:00:00"),
        moment("2021-11-26 23:59:59")
      )
        ? "offer"
        : ""
        }`
      }
    >
      <div className="bgHolder">
        <div className="container">
          <div className="heroCTA">
            <h2>
              {title && title.node[lang].split(" ")[0]}
              <br />
              {title && title.node[lang].split(" ").slice(1).map((el) => el).join(" ")}
            </h2>
            <p>
              {subText && subText.node[lang].split(",")[0]},
              <br />
              {subText && subText.node[lang].split(",").slice(1).map((el) => el).join(",")}
            </p>
            <HalfButton2 price={lang === "gr" ? "€ 18.50/μήνα" : "€18.50/month"} lang={lang} data={data} />
            <Guarantees lang={lang} data={data} />
          </div>
          <img
            alt="footer cross"
            src="/footercross.svg"
            className="pharma"
            width="59"
            height="59"
          />
        </div>
      </div>
    </div >)
};

export default Hero;
