import React from "react";
import Button from "./button";
import { Link } from "gatsby";

const FeatureSingle = ({ feature, children, isTextSideRight = true }) => {
  const addLeftClass = originalClass => {
    if (isTextSideRight === true && originalClass === "sideImg") {
      return originalClass + " left";
    } else if (isTextSideRight === false && originalClass === "feature") {
      return originalClass + " left";
    }
    return originalClass + " right";
  };
  return (
    <div className="featureRow">
      <div
        className={addLeftClass("sideImg")}
        style={{ backgroundImage: `url("${feature.image}")` }}
      >
        {feature.video && (
          <div className="embed-container">
            <iframe
              src={`https://www.youtube.com/embed/${feature.video}?modestbranding=1&rel=0&widget_referrer=homepage`}
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        )}
        <div className="pink"></div>
      </div>
      <div className={addLeftClass("feature")}>
        <div className="featureTextHolder">
          <h2>{feature.title}</h2>
          {children}
          {feature.ctaText ? (
            <Button link={feature.ctaLink} className="featurebtn">
              {feature.ctaText}
            </Button>
          ) : (
            false
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureSingle;
