import React from "react";
import Button from "./button";
import Img from "gatsby-image";
import { Link } from "gatsby";

const Community = ({ images, data, lang }) => {
  const gallery = images.edges;
  const title = data && data.find((el) => el.node.label === "community-title-1")
  const title1 = data && data.find((el) => el.node.label === "community-title-2")

  return (
    <div className="container community">
      <div className="social">
        <h3>{title && title.node[lang]}</h3>
        <p>
          {title1 && title1.node[lang].split("Great")[0]} <strong>Great</strong>
        </p>
        <div className="galleryHolder">
          {gallery.map((g, i) => (
            <a
              key={i}
              href={`https://www.instagram.com/p/${g.node.id}`}
              title={g.node.caption}
              target="_blank"
              rel="noopener"
              className={i === 0 ? "galleryMainImage" : "galleryImage"}
            >
              <Img
                key={i}
                fluid={g.node.localFile.childImageSharp.fluid}
                alt={g.node.caption}
              />
            </a>
          ))}
        </div>
        <div className="clearall"></div>
      </div>
      {/* <Button className="featurebtn">Δείξε μου περισσότερα</Button> */}
    </div>
  );
};

export default Community;
